import React from 'react';

import { Analytics } from '@blush/services';

import type { DoodleIpsum } from '@blush/types';

declare module '@blush/services' {
  export interface AnalyticsEvents {
    ['Change style']: { style: string };
    ['Change size']: { size: DoodleIpsum.ImageSize };
    ['Change background']: { color: string };
    ['Copy code']: { imageId?: string; value: string };
    ['Shuffle']: { card: string };
    ['Click API waitlist']: void;
  }

  export interface AnalyticsIdentifyTraits {}
}

const analytics = new Analytics();

export function useAnalytics() {
  return React.useMemo(
    () => ({
      identify: analytics.identify,
      reset: analytics.reset,
      track: analytics.track,
      page: analytics.page,
      enable() {
        analytics.enabled = true;
      },
    }),
    [],
  );
}
