export const IS_DEV =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'development' ||
  !process.env.NEXT_PUBLIC_VERCEL_ENV;
export const IS_PROD = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
export const IS_PREVIEW = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview';

const BASE_URL_PROD = 'https://doodleipsum.com';
const BASE_URL_PREVIEW = `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
const BASE_URL_DEV = 'http://localhost:4000';

export const BASE_URL = IS_DEV
  ? BASE_URL_DEV
  : IS_PREVIEW
  ? BASE_URL_PREVIEW
  : BASE_URL_PROD;
export const IMGIX_DOMAIN = IS_DEV
  ? 'blush-images-staging.imgix.net'
  : 'images.blush.design';

export const API_WAITLIST_URL = 'https://346c4tr45ti.typeform.com/to/Ida6GfG5';
export const BLUSH_LICENSE_URL = 'https://blush.design/license';
export const BLUSH_TERMS_URL = 'https://blush.design/terms';
export const BLUSH_PRIVACY_URL = 'https://blush.design/privacy';
export const BLUSH_INSTAGRAM_URL = 'https://www.instagram.com/blushdesignapp/';
export const BLUSH_TWITTER_URL = 'https://twitter.com/blushdesignapp';

export const ASPECT_RATIOS = [
  { label: '1:1', value: [1, 1] },
  { label: '16:9', value: [16, 9] },
  { label: '9:16', value: [9, 16] },
  { label: '4:3', value: [4, 3] },
  { label: '3:4', value: [3, 4] },
  { label: 'Auto', value: null },
];

export const ASPECT_RATIOS_OPTIONS = [
  ...ASPECT_RATIOS,
  { label: 'Custom', value: 'custom' },
];

export const DEFAULT_WIDTH = 700;
export const DEFAULT_HEIGHT = 700;

export const RANDOM_COLORS = [
  '#6392D9',
  '#63C8D9',
  '#7463D9',
  '#825DEB',
  '#C863D9',
  '#D96363',
  '#D98D63',
  '#EB765D',
  '#FF3C3C',
  '#3D27F6',
  '#52DF71',
];
