import React from 'react';
import { useRouter } from 'next/router';
import type { AppProps } from 'next/app';

import '@blush/mascara/styles';
import 'styles/global.css';

import { IS_DEV } from 'common/constants';
import { useAnalytics } from 'hooks/useAnalytics';

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter();
  const analytics = useAnalytics();

  React.useEffect(() => {
    if (!IS_DEV) {
      analytics.enable();
    }

    function handleRouteChange() {
      analytics.page(router.asPath);
    }

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [analytics, router]);

  return <Component {...pageProps} />;
};

export default App;
