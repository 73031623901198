export interface AnalyticsProvider {
  track: (event: string, ...args: any[]) => any;
  page: (url: string, ...args: any[]) => any;
  identify: (id: string, ...args: any[]) => any;
  reset: () => any;
}

// Discourage use of global.analytics outside of this service
declare global {
  var analytics: never;
}

// Override these types in your application:
// declare module '@blush/services' {
//   export interface AnalyticsEvents {
//     myEvent: { somePayload: string };
//   }
//   export interface AnalyticsIdentifyTraits {
//     myTrait: { somePayload: string };
//   }
// }
export interface AnalyticsEvents {}
export interface AnalyticsIdentifyTraits {}

export type AnalayticsMetaData = {
  [key: string]: any;
};

export type AnalyticsTrackOptions = {
  integrations?: { [key: string]: boolean };
};

export type AnalyticsEventType = keyof AnalyticsEvents;

export type TrackCallback = <T extends AnalyticsEventType>(
  event: T,
  ...rest: AnalyticsEvents[T] extends {} ? [AnalyticsEvents[T]] : []
) => void;

export interface AnalyticsOptions {
  getTrackOptions?: () => AnalyticsTrackOptions;
  getMetaData?: () => AnalayticsMetaData;
}

export class Analytics {
  private _enabled = false;

  public set enabled(enabled) {
    this._enabled = enabled;
  }

  public get enabled() {
    return this._enabled && !!this.provider;
  }

  public set provider(provider: AnalyticsProvider) {
    global.analytics = provider as never;
  }

  public get provider() {
    return global.analytics as AnalyticsProvider;
  }

  private get trackOptions() {
    return this.options.getTrackOptions?.();
  }

  private get metadata() {
    return this.options.getMetaData?.();
  }

  constructor(private options: AnalyticsOptions = {}) {}

  identify = (id: string, traits = {} as AnalyticsIdentifyTraits) => {
    if (!this.enabled) {
      console.info(`[Analytics Identify]: ${id} ${JSON.stringify(traits)}`);
      return;
    }

    this.provider.identify(id, traits);
  };

  reset = () => {
    if (!this.enabled) {
      return;
    }

    this.provider.reset();
  };

  page = (url: string) => {
    if (!this.enabled) {
      console.info(`[Analytics Page]: ${url}`);
      return;
    }

    this.provider.page(url);
  };

  track: TrackCallback = (event, ...rest) => {
    if (!this.enabled) {
      console.info(`[Analytics Track]: ${event} ${rest.map((v) => JSON.stringify(v)).join('\n')}`);
      return;
    }

    const [data = {}] = rest;

    const { metadata, trackOptions = {} } = this;

    this.provider.track(
      event,
      { ...metadata, ...data },
      {
        ...trackOptions,
        integrations: {
          ...trackOptions.integrations,
          All: true,
        },
      },
    );
  };
}
